
import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/:uuid',
    name: 'Increases',
    component: async () => await import('@/views/IncreasesView.vue')
  }
]

export default routes
