import { defineStore } from 'pinia'
import * as api from './integrations'
import { CreateIncreasePayload, Majoracao } from './models'

const useDisputeStore = defineStore('disputeStore', () => {
  async function searchIncreaseByCode (code: string = '', email: string = ''): Promise<Majoracao> {
    const query = { email }
    const response = await api.searchIncreaseByCode(code, query)

    return response.value
  }

  async function createIncrease (code: string, email: string, payload: CreateIncreasePayload): Promise<Majoracao> {
    const query = { email }
    const response = await api.createIncrease(code, query, payload)

    return response.value
  }

  return {
    searchIncreaseByCode,
    createIncrease
  }
})

export default useDisputeStore
