import Vue from 'vue'
import Notification from '@/models/notifications/Notification'

const mutationsNotifications = {
  setNotifications: (state, data) => {
    const notifications = (data.data ? data.data : data)
      .map(notification => new Notification(notification))

    Vue.set(state, 'notifications', notifications)
  },

  setMentions: (state, mentions) => {
    const { number, content } = mentions

    Vue.set(state, 'mentionNotifications', {
      ...mentions,
      content: number > 0 ? [...state.mentionNotifications.content, ...content] : content
    })
  },

  setMentionsSummary: (state, data) => Vue.set(state, 'mentionNotificationsSummary', data),

  toggleShowNotifications: (state, open) => {
    state.notificationsVisible = false
  },

  setNotificationsVisible: (state, visibility) => {
    state.notificationsVisible = visibility
  },

  setMentionReaded: (state, { payload: { mentionId } }) => {
    state.mentionNotifications.content.forEach((item, index) => {
      if (Number(item.id) === Number(mentionId)) {
        Vue.set(state.mentionNotifications.content[index], 'readDate', new Date().getTime())
      }
    })
  },

  setMentionsOnlyRead(state, onlyRead) {
    Vue.set(state, 'seeMentionsReaded', onlyRead)
  },

  setBatchActionsProgress(state, data) {
    Vue.set(state, 'batchActionsProgress', data)
  }
}

export default mutationsNotifications
