import Vue from 'vue'

export default {
  setActiveOccurrency: (state, activeOccurrency) => (state.activeOccurrency = activeOccurrency),

  setSelectedIds(state, ids) {
    const {
      useUnselectedIds,
      getIsAllIdsSelected: wereAllSelected,
      disputeQuery,
      disputes
    } = this.getters
    const loadedDisputesLength = (disputes ?? []).length
    const isAllLoaded = loadedDisputesLength > 0 && loadedDisputesLength === disputeQuery?.total
    const isAllSelected = loadedDisputesLength > 0 && ids.length === loadedDisputesLength
    const isNothingSelected = ids.length === 0
    const onlyOneIdOfDiff = Math.abs(state.selectedIds.length - ids.length) === 1
    const hasUnselectedIDs = state.unselectedIds.length > 0
    const allIDs = disputes.map(({ getDisputeId }) => getDisputeId)

    if (isAllLoaded || isAllSelected || isNothingSelected || !useUnselectedIds) {
      Vue.set(state, 'unselectedIds', [])
    } else if ((wereAllSelected || hasUnselectedIDs) && onlyOneIdOfDiff) {
      Vue.set(state, 'unselectedIds', allIDs.filter(id => !(ids.includes(id))))
    }

    Vue.set(state, 'selectedIds', [...ids])
  },

  setDisputesViolatedRights(state, { data: violatedRights, payload: disputeId }) {
    Vue.set(state.disputesViolatedRights, disputeId, violatedRights)
  }
}
