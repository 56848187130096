import 'systemjs-webpack-interop/auto-public-path/auto-public-path'
import Vue from 'vue'
// import App from '@/App.vue'
import singleSpaVue from 'single-spa-vue'
import router from './router'
import store from './store'
import { validateWorkspace } from '@/utils/validations'
import VSanitize from 'v-sanitize'

// FILTERS
import '@/filters/numberFormat'
import '@/filters/capitalize'
import '@/filters/cpfCnpj'
import '@/filters/filterTj'
import '@/filters/currency'
import '@/filters/firstName'
import '@/filters/phoneNumber'
import '@/filters/resumedName'
import '@/filters/ownName'
import '@/filters/oab'

// STYLES
import '@/styles/core.scss'
import '@/styles/transitions.scss'
import '@/styles/perfect-scroll.scss'
import '@/styles/quill-editor.scss'

// PLUGINS
import i18n from '@/plugins/vueI18n'
import '@/plugins/axios'
import '@/plugins/ckEditor'
import '@/plugins/element'
import '@/plugins/jusNotification'
import '@/plugins/jusSegment'
import '@/plugins/jusAmplitude'
import '@/plugins/socket'
import '@/plugins/vueAwesomeSwiper'
import '@/plugins/vueChatScroll'
import '@/plugins/vueMoment'
import '@/plugins/vueMoney'
import '@/plugins/vueTheMask'
import '@/plugins/shortkey'
import '@/plugins/vueHtml2pdf'

// GLOBAL COMPONENTS
import JusIcon from '@/components/images/JusIcon'
import JusAvatarUser from '@/components/images/JusAvatarUser'
import JusViewMain from '@/components/layouts/JusViewMain'
import JusButtonBack from '@/components/buttons/JusButtonBack'

Vue.use(VSanitize)
Vue.component('JusIcon', JusIcon)
Vue.component('JusAvatarUser', JusAvatarUser)
Vue.component('JusViewMain', JusViewMain)
Vue.component('JusButtonBack', JusButtonBack)

Vue.config.productionTip = false

if (store.getters.workspaceSubdomain) {
  // eslint-disable-next-line no-undef
  axios.defaults.headers.common.Workspace = store.getters.workspaceSubdomain
}

const vueLifecycles = singleSpaVue({
  Vue,
  loadRootComponent: () => {
    return import('./App.vue').then(module => module.default)
  },
  appOptions: {
    // render: h => h(App, {
    //   props: {
    //     // single-spa props are available on the "this" object. Forward them to your component as needed.
    //     // https://single-spa.js.org/docs/building-applications#lifecycle-props
    //     // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
    //     /*
    //     name: this.name,
    //     mountParcel: this.mountParcel,
    //     singleSpa: this.singleSpa,
    //       */
    //   }
    // }),
    router,
    store,
    i18n
  }
})

const init = (props) => vueLifecycles.mount(props).then(() => {
  validateWorkspace()
})

export let mountParcel
export let unmountParcel
export const bootstrap = [
  (props) => {
    mountParcel = props.mountParcel
    unmountParcel = props.unmount
    return Promise.resolve()
  },
  vueLifecycles.bootstrap
]
export const mount = (props) => Promise.resolve().then(() => {
  if (store.getters.isLoggedIn) {
    store.dispatch('myAccount')
      .then(() => {
        store.dispatch('getWorkspaceMembers')
        store.dispatch('getWorkspaceTags')
      }).catch(() => {
        store.dispatch('logout')
      }).finally(() => {
        return init(props)
      })
  } else {
    return init(props)
  }
})
export const unmount = vueLifecycles.unmount
