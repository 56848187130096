import he from 'he'

function htmlToString(html) {
  if (html != null) {
    return html.replace(/<[^>]*>?/gm, '')
      .replace(/\r?\n|\r/g, '')
      .replace(/(\s{2,})|(&nbsp;)/g, ' ')
      .trim()
  } else {
    return ''
  }
}

function shortContent(content = '') {
  if (content && content !== '') {
    content = he.decode(content)
    content = htmlToString(content)
    content = content.replace('<br />', ' ')
    content = content.replace(/[\t\r\n]/g, ' ')
    content = content.replace('  ', ' ')
    content = content.length >= 140 ? content.substring(0, 140) : content
  } else {
    content = ''
  }

  return content
}

export default shortContent
