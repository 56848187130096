import { DisputeModel } from '@/models/dispute/DisputeModel'
import { axiosDispatch, buildQuery } from '@/utils/'

const disputeApi = '/api/justto-web-management-bff/v2'
const disputeV2Api = '/api/disputes/v2'

const getCorrespondingTab = disputeStatus => {
  switch (disputeStatus) {
    case 'PRE_NEGOTIATION':
      return 'pre-negotiation'
    case 'IMPORTED':
    case 'ENRICHED':
    case 'ENGAGEMENT':
    case 'PENDING':
      return 'engagement'
    case 'RUNNING':
      return 'running'
    case 'ACCEPTED':
    case 'CHECKOUT':
      return 'accepted'
    case 'UNSETTLED':
    case 'SETTLED':
    case 'CANCELED':
    case 'EXPIRED':
      return 'finished'
    default:
      return 'engagement'
  }
}

const overviewActions = {
  async getTickets({ state, dispatch, commit, getters: { useDisputeProjection, workspaceId, accountId } }, command) {
    commit('incrementTicketsGettersCounter')

    if (!(['nextPage'].includes(command))) dispatch('setTicketsQuery', { key: 'page', value: 1 })

    return axiosDispatch({
      url: `${disputeApi}/filter${buildQuery({ ...state.ticketsQuery, useDisputeProjection, workspaceId, accountId })}`,
      method: 'POST',
      mutation: 'setCommunicationTickets',
      payload: command || 'firstPage'
    }).finally(() => commit('decrementTicketsGettersCounter'))
  },

  getTicketsNextPage({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('addTicketQueryPage')
      dispatch('getTickets', 'nextPage')
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  setTicketsQuery({ commit }, params) {
    commit('setTicketsQuery', params)
  },

  setTicketPrescription({ commit }, prescription) {
    commit('setTicketPrescription', prescription)
  },

  unsetTicketPrescription({ commit }, prescription) {
    commit('unsetTicketPrescription', prescription)
  },

  setTicketsFilters({ commit }, { filters, hasFilters, preventFilters, preventSocket }) {
    commit('setTicketsFilters', filters)
    commit('setTicketsHasFilters', Boolean(hasFilters))
    commit('setPreventFilters', Boolean(preventFilters))
    commit('setPreventSocket', Boolean(preventSocket))
  },

  setTicketsActiveTab({ commit, getters }, activeTab) {
    commit('setTicketsActiveTab', activeTab)
    localStorage.setItem('TICKET_ACTIVE_TAB', activeTab)
  },

  setHideTicket({ commit }, hidde) {
    commit('setHideTicket', Boolean(hidde))
  },

  updateActiveTab({ state, commit }, disputeStatus) {
    const correspondingTab = getCorrespondingTab(disputeStatus)
    const isUpdate = ['ACCEPTED', 'CHECKOUT', 'SETTLED'].includes(disputeStatus) && state.tickets.content.length

    if (correspondingTab !== state.ticketsActiveTab && isUpdate) {
      const vue = document.querySelector('#app').__vue__

      document.querySelectorAll('.el-notification.info.right').forEach(tag => tag.__vue__.$parent.close())

      vue.$jusNotification({
        title: 'há atualizações nesta disputa',
        message: 'Clique se quiser recarregar ou feche se quiser continuar nesta página.',
        type: 'info',
        iconClass: 'el-icon-info',
        onClick: () => {
          document.querySelector('.el-notification.info.right').__vue__.$parent.close()
          commit('setTicketsActiveTab', correspondingTab)
        }
      })
    }
  },

  updateTicketOnDemand({ rootState, state, commit, dispatch }, ticket) {
    const correspondingTab = getCorrespondingTab(ticket.getDisputeStatus)

    if (rootState.negotiationTicketsModule.ticketsPreventSocket) return

    if (rootState.negotiationOverviewModule.ticketOverview.disputeId === Number(ticket.getDisputeId)) {
      const isUpdate = ['ACCEPTED', 'CHECKOUT', 'SETTLED'].includes(ticket.getDisputeStatus) && !['ACCEPTED', 'CHECKOUT', 'SETTLED'].includes(rootState.negotiationOverviewModule.ticketOverview.status)
      const isInNegotiation = location.href.includes('negotiation')
      const isCorrespondingTab = correspondingTab !== state.ticketsActiveTab

      if (process.env.NODE_ENV === 'development') {
        console.table({
          isCorrespondingTab,
          isUpdate,
          isInNegotiation,
          status: ticket.getDisputeStatus
        })
      }

      if (isCorrespondingTab && isUpdate && isInNegotiation) {
        const vue = document.querySelector('#app').__vue__

        document.querySelectorAll('.el-notification.info.right').forEach(tag => tag.__vue__.$parent.close())

        vue.$jusNotification({
          title: 'há atualizações nesta disputa',
          message: 'Clique se quiser recarregar ou feche se quiser continuar nesta página.',
          type: 'info',
          iconClass: 'el-icon-info',
          duration: 50000,
          onClick: () => {
            document.querySelector('.el-notification.info.right').__vue__.$parent.close()
            commit('setTicketsActiveTab', correspondingTab)
          }
        })
      } else {
        commit('updateTicketItem', ticket) // Atualiza na listagem.
      }

      commit(ticket.dtoVersion === '3' ? 'updateTicketFields' : 'updateTicket', ticket)
    } else {
      if (correspondingTab !== state.ticketsActiveTab) {
        commit('deleteTicket', { payload: ticket.getDisputeId })
      } else {
        commit('updateTicketItem', ticket) // Atualiza na listagem.
      }
    }
  },

  SOCKET_ADD_DISPUTE({ getters, dispatch }, dispute) {
    if (getters.useSocketUpdateDispute === true) return

    const ticket = new DisputeModel({ ...dispute, dtoVersion: '1' })

    dispatch('updateTicketOnDemand', ticket)
  },

  SOCKET_REMOVE_DISPUTE({ commit }, dispute) {
    const ticket = new DisputeModel(dispute)
    commit('deleteTicket', { payload: ticket.getDisputeId })
  },

  SOCKET_UPDATE_DISPUTE({ dispatch, commit, getters }, disputeModel) {
    if (getters.useSocketUpdateDispute === false) return

    dispatch('updateDisputeOnDemand', disputeModel)
    dispatch('updateTicketOnDemand', disputeModel)
  },

  SOCKET_UPDATE_DISPUTE_SUMMARY({ getters, commit }, summaries) {
    if (getters.useSocketUpdateDispute === false) return

    const statusMap = {
      PRE_NEGOCIACAO: 'PRE_NEGOTIATION',
      SEM_RESPOSTA: 'ENGAGEMENT',
      EM_NEGOCIACAO: 'INTERACTION',
      PROPOSTA_ACEITA: 'NEW_DEAL',
      FINALIZADOS: 'FINISHED'
    }

    const nearExpiration = summaries.filter(({ status }) => (
      status === 'SEM_RESPOSTA'
    )).map(summary => ({
      disputePhase: statusMap[summary.status],
      quantity: summary.quantidade,
      type: 'DISPUTE_NEAR_EXPIRATION',
      messageId: `${statusMap[summary.status]}-${summary.quantidade}-DISPUTE_NEAR_EXPIRATION`
    }))

    const notVizualizeds = summaries.filter(({ status }) => (
      ['PRE_NEGOCIACAO', 'EM_NEGOCIACAO', 'PROPOSTA_ACEITA', 'FINALIZADOS'].includes(status)
    )).map(summary => ({
      disputePhase: statusMap[summary.status],
      quantity: summary.quantidade,
      type: 'DISPUTE_NOT_VISUALIZED',
      messageId: `${statusMap[summary.status]}-${summary.quantidade}-DISPUTE_NOT_VISUALIZED`
    }))

    commit('setSummaryNearExpirations', nearExpiration)
    commit('setSummaryNotVisualizeds', notVizualizeds)
  },

  searchCaseResumeById({ commit }, disputeId) {
    axiosDispatch({
      url: `${disputeV2Api}/${disputeId}/dispute-objects`,
      mutation: 'setFullCaseResume'
    })
  },

  acceptObfCase(_, { pedidoId, disputeId }) {
    return axiosDispatch({
      url: `${disputeV2Api}/${disputeId}/dispute-objects/accepted/${pedidoId}`,
      method: 'POST'
    })
  }
}

export default overviewActions
