import Vue from 'vue'
import store from '@/store'

const JusSegment = {
  install(Vue) {
    Vue.prototype.$jusSegment = (event, prop) => {
      const properties = {
        userId: store.getters.accountEmail,
        workspace: store.getters.workspaceName,
        team: store.getters.workspaceTeamName,
        source: 'front'
      }
      Object.assign(properties, prop)
      window.analytics.track(event, properties, () => {})
    }
    Vue.prototype.$jusSegmentPage = (page) => {
      window.analytics.page(page, () => {})
    }
  }
}

Vue.use(JusSegment)
