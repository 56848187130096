import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '@/store'
import routes from './routes'
import events from '@/constants/negotiationEvents'
import EDITOR_TABS from '@/constants/editor'
import { eventBus, validateLocalWorkspace } from '@/utils'

const vue = () => document.getElementById('app')?.__vue__
const originalPush = VueRouter.prototype.push
const handleNext = (value, next) => next({
  headers: {
    // Permite apenas iframes de sites confiáveis
    'X-Frame-Options': 'SAMEORIGIN'
  },
  ...(value ?? {})
})

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {})
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.fullPath.includes('#/')) {
    next(to.fullPath.replace('#/', ''))
    return
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!validateLocalWorkspace()) {
      const { name, params, query } = to

      sessionStorage.setItem('redirect', JSON.stringify({ name, params, query }))
      handleNext({ name: 'login' }, next) // Não tem Workspace na sessionStorage
    } else if (localStorage.getItem('justoken')) {
      if (Store.getters.hasWorkspace) {
        if (to.name === 'workspaces') {
          if (!Store.getters.isJusttoAdmin) {
            if (localStorage.getItem('jusprofile') === 'ADMINISTRATOR') {
              handleNext({ name: 'dashboard' }, next)
            } else {
              handleNext({ name: 'negotiation' }, next)
            }
          }
        }

        if (from.name === 'login') {
          Store.dispatch('myAccount')
        }

        if (from.name === 'ticket' && from.params?.id) {
          eventBus.$emit(events.TICKET_WEB_SOCKET_DISCONNECT.callback, 'unsubscribe', from?.params?.id)
          // TODO: Resetar aba de tickets
          Store.commit('setOmnichannelActiveTab', EDITOR_TABS.MESSAGES)
        }

        if (to.name === 'onboarding' && !Store.getters.redirectNewWorkspace) {
          // handleNext({ name: 'dashboard' }, next)
          handleNext({}, next)
        } else {
          handleNext({}, next)
        }
      } else {
        if (to.name === 'onboarding') {
          handleNext({}, next)
        } else {
          handleNext({ name: 'login' }, next)
        }
      }
    } else {
      if (from.name === 'login') {
        vue().$jusNotification({
          message: 'Tente novamente!',
          type: 'warning'
        })

        window.location.reload()
      }
      next('login')
    }
  } else if (from.query.token) {
    next(false)
  } else {
    handleNext({}, next)
  }

  if (to.name === 'ticket') {
    eventBus.$emit(events.TICKET_CHANGE.callback, to.params.id, from?.params?.id)

    if (from.name !== 'ticket' && to.params?.id) {
      eventBus.$emit(events.TICKET_CHANGE.callback, to.params.id, from?.params?.id)
    }
  }
})

router.afterEach((to) => {
  if (['justto.app', 'acordos.projuris'].includes(window.location?.hostname)) {
    const message =
    `<p>
      Oi, só queríamos te avisar que o endereço da nossa plataforma mudou.
      <br>
      <br>
      Agora você deve utilizar o nosso novo endereço <a href="https://projuris.acordos.app">projuris.acordos.app</a>.
      <br>
      <br>
      Não esqueça de adicionar esse novo endereço aos seus favoritos para poder acessar facilmente.
    </p>`

    vue().$alert(message, 'Atenção', {
      confirmButtonText: 'OK',
      dangerouslyUseHTMLString: true,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      closeOnHashChange: false,
      showClose: false,
      callback: () => {
        window.location.replace('https://projuris.acordos.app')
      }
    })
  }

  if (to.matched.some(record => record.meta.trackPage)) {
    const properties = {
      userId: Store.getters.accountEmail,
      workspace: Store.getters.workspaceName,
      team: Store.getters.workspaceTeamName,
      source: 'front'
    }

    window.analytics.page(to.name, properties, () => {
      if (process.env.NODE_ENV === 'development') {
        console.table({ page: to.name, properties })
      }
    })
  }

  if (to.name === 'onboarding' || to.name === 'login' || to.name === 'register' || to.name === 'forgot-password') {
    document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width,initial-scale=1.0')
  } else {
    document.querySelector('meta[name="viewport"]').setAttribute('content', '')
  }
})

export default router
