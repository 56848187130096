import { axiosDispatch, buildQuery, dispatchCustomEvent } from '@/utils/'

const FileSaver = require('file-saver')
const documentsPath = 'api/office/documents'
const molServicePath = 'api/resposta-mol-service'
const minutaBffPath = 'api/gestao-minuta-bff'

/**
 * Dispatch Actions
 * @type {Promise}
 * @param {string} url - the end-point reqeust
 * @param {string} method - the method of requesr (default is 'GET')
 * @param {Object, array} data - the params  of request
 */

const documentActions = {
  getDocumentModels({ getters }) {
    console.log({ useOwnEditor: getters.useOwnEditor })
    return axiosDispatch({
      url: `${minutaBffPath}/v1/document/templates${buildQuery({ useOwnEditor: getters.useOwnEditor })}`
    })
  },

  createDocumentByModel({ getters }, params) {
    return new Promise((resolve, reject) => {
      const query = { modelId: params.modelId, disputeId: params.disputeId }

      axiosDispatch({
        url: `${minutaBffPath}/v1/document/create${buildQuery({ ...query, useOwnEditor: getters.useOwnEditor })}`,
        method: 'POST'
      }).then(payload => {
        dispatchCustomEvent('resolveTodoItem', {
          disputeId: params.disputeId,
          todoItemCode: 'TERMO_NAO_GERADO'
        })

        resolve(payload)
      }).catch(reject)
    })
  },

  getDocumentByDisputeId({ getters }, disputeId) {
    return new Promise((resolve, reject) => {
      axiosDispatch({
        url: `${minutaBffPath}/v1/document${buildQuery({ disputeId, useOwnEditor: getters.useOwnEditor })}`
      }).then(response => {
        if (response?.id == null) {
          resolve(null)
        } else {
          resolve(response)
        }
      }).catch(reject)
    })
  },

  setDocumentSigners({ getters }, params) {
    return new Promise((resolve, reject) => {
      const url = `${minutaBffPath}/v1/document/sign${buildQuery({ disputeId: params.disputeId, useOwnEditor: getters.useOwnEditor })}`

      axiosDispatch({
        url: url,
        method: 'POST',
        data: {
          assinantes: params?.recipients ?? [],
          observadores: params?.observers ?? []
        }
      }).then(payload => {
        dispatchCustomEvent('resolveTodoItem', {
          disputeId: params.disputeId,
          todoItemCode: 'TERMO_GERADO_NAO_ENVIADO'
        })

        resolve(payload)
      }).catch(reject)
    })
  },

  sendDocumentToProcon(_, { disputeId, documentId, contact }) {
    return new Promise((resolve, reject) => {
      axiosDispatch({
        url: `${molServicePath}/v1/${disputeId}/${documentId}/${contact}`,
        method: 'POST'
      }).then(payload => {
        dispatchCustomEvent('resolveTodoItem', {
          disputeId: disputeId,
          todoItemCode: 'TERMO_GERADO_NAO_ENVIADO'
        })

        resolve(payload)
      }).catch(reject)
    })
  },

  resendSignersNotification({ getters }, params) {
    return new Promise((resolve, reject) => {
      axiosDispatch({
        url: `${minutaBffPath}/v1/document/notify${buildQuery({ useOwnEditor: getters.useOwnEditor })}`,
        method: 'PUT',
        data: { disputeId: params.disputeId }
      }).then(payload => {
        dispatchCustomEvent('resolveTodoItem', {
          disputeId: params.disputeId,
          todoItemCode: 'TERMO_PENDENTE_ASSINATURA'
        })

        resolve(payload)
      }).catch(reject)
    })
  },

  deleteDocument({ getters }, disputeId) {
    return axiosDispatch({
      url: `${minutaBffPath}/v1/document${buildQuery({ useOwnEditor: getters.useOwnEditor })}`,
      method: 'DELETE',
      data: { disputeId }
    })
  },

  backDocumentToEditing({ getters }, disputeId) {
    return axiosDispatch({
      url: `${minutaBffPath}/v1/document/reedit${buildQuery({ useOwnEditor: getters.useOwnEditor })}`,
      method: 'PATCH',
      data: { disputeId }
    })
  },

  addModel(_, url) {
    return axiosDispatch({
      url: `${documentsPath}/model?url=${url}`,
      method: 'POST',
      data: {}
    })
  },

  editModel(_, model) {
    return axiosDispatch({
      url: `${documentsPath}/model`,
      method: 'PUT',
      data: model
    })
  },

  deleteModel(_, modelId) {
    return axiosDispatch({
      url: `${documentsPath}/model/${modelId}`,
      method: 'DELETE'
    })
  },

  downloadDocument(_, params) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      axios.get(`${documentsPath}/download-signed/${params.disputeId}`, {
        responseType: 'arraybuffer'
      }).then(response => {
        const blob = new Blob([response.data], {
          type: 'application/octet-stream'
        })
        const fileName = params.name + '.pdf'
        FileSaver.saveAs(blob, fileName)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getDocumentUrl(_, disputeId) {
    return axiosDispatch({
      url: `${documentsPath}/${disputeId}/draft/url`
    })
  },

  getDocumentTypes() {
    return axiosDispatch({ url: `${documentsPath}/model/input/types` })
  },

  getDefaultAssigners({ getters }) {
    return axiosDispatch({
      url: `${minutaBffPath}/v1/document/signers/default${buildQuery({ useOwnEditor: getters.useOwnEditor })}`,
      mutation: 'createFromDefaultSigners'
    })
  },

  setSelectedSigners({ commit }, signers) {
    commit('setSelectedSigners', Object.values(signers))
  },

  cleanSelectedSigners({ commit }) {
    commit('setSelectedSigners', [])
  },

  saveMinimizedDraft({ commit }, dispute) {
    return Promise.all([
      commit('setStoredDrafts', dispute)
    ])
  },

  forgetMinimizedDraft({ commit }, id) {
    return Promise.all([
      commit('deleteStoredDrafts', id)
    ])
  },

  openStoredDraft({ commit }, id = null) {
    return Promise.all([
      commit('setOpenDraftId', id)
    ])
  },

  getSignerStatus({ getters }) {
    return axiosDispatch({
      url: `${minutaBffPath}/v1/document/status${buildQuery({ useOwnEditor: getters.useOwnEditor })}`,
      mutation: 'setSignerStatus'
    })
  },

  getAvailableProcons() {
    return axiosDispatch({
      url: 'api/resposta-mol-service/v1/procons',
      mutation: 'setAvailableProcons'
    })
  },

  editDraftContent(_, { disputeId, content }) {
    console.log('editDraftContent', new Date().toUTCString(), { disputeId, content })

    return axiosDispatch({
      url: `${minutaBffPath}/v1/document/content`,
      method: 'PUT',
      data: { disputeId, content }
    })
  }
}

export default documentActions
