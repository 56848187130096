const en = {
  tabs: {
    team: 'Teams',
    featuresAndModules: 'Features and modules',
    workspaceData: 'Account data'
  },
  configure: 'Configure',
  featuresAndModules: {
    authorization: {
      type: {
        API_KEY: 'API Key Authentication',
        BEARER_TOKEN: 'Authorization by Token',
        BASIC_AUTH: 'Basic Authorization'
      }
    }
  },
  'integration-types': {
    PROJURIS_SOAP: 'Projuris Interprises',
    FINCH: 'Finch Webservices',
    JUSTTO_WEBHOOK: 'Webhook default of Projuris Acordos'
  },
  boolean: 'Yes | No'
}

export default en
