import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache) {
  const _component_RouterView = _resolveComponent("RouterView");
  const _component_el_config_provider = _resolveComponent("el-config-provider");
  return _openBlock(), _createBlock(_component_el_config_provider, {
    namespace: "pconf"
  }, {
    default: _withCtx(() => [_createVNode(_component_RouterView)]),
    _: 1
  });
}