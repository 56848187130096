class ProposalVm {
  constructor({ roleId, value, name }) {
    this.roleId = roleId
    this.value = value
    this.name = name
  }
}

// FIXME: SAAS-7080 Mapear restante das propriedades do DisputeModelV3
export default class LastOfferVm {
  constructor({
    defendantOffer,
    lastOfferRoleId,
    lastOfferValue, getDisputeLastOfferValue,
    lastOfferName,
    plaintiffOffer,
    lastCounterOfferRoleId,
    lastCounterOfferValue, getDisputeLastCounterOfferValue,
    lastCounterOfferName, getDisputeLastCounterOfferName,
    disputeUpperRange, getDisputeUpperRange,
    disputeDealValue, getDisputeDealValue
  }) {
    this.acceptedValue = disputeDealValue ?? getDisputeDealValue ?? 0

    if (disputeUpperRange || getDisputeUpperRange) {
      this.defendantOffer = new ProposalVm(defendantOffer ?? {
        roleId: lastOfferRoleId,
        value: lastOfferValue ?? getDisputeLastOfferValue,
        name: lastOfferName
      })
      this.plaintiffOffer = new ProposalVm(plaintiffOffer || {
        roleId: lastCounterOfferRoleId,
        value: lastCounterOfferValue ?? getDisputeLastCounterOfferValue,
        name: lastCounterOfferName ?? getDisputeLastCounterOfferName
      })
    } else {
      this.plaintiffOffer = new ProposalVm(defendantOffer || {
        roleId: lastOfferRoleId,
        value: lastOfferValue ?? getDisputeLastOfferValue,
        name: lastOfferName
      })
      this.defendantOffer = new ProposalVm(plaintiffOffer || {
        roleId: lastCounterOfferRoleId,
        value: lastCounterOfferValue ?? getDisputeLastCounterOfferValue,
        name: lastCounterOfferName ?? getDisputeLastCounterOfferName
      })
    }
  }
}
