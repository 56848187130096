import Vue from 'vue'
import store from '@/store'

const jusAmplitude = {
  install(Vue) {
    Vue.prototype.$jusAmplitude = (event, prop) => {
      const properties = {
        userId: store.getters.accountEmail,
        workspace: store.getters.workspaceName,
        team: store.getters.workspaceTeamName,
        source: 'front#potal-legacy',
        ...prop
      }

      window.analytics.track(event, properties, () => {
        if (window.amplitude != null) {
          window.amplitude.track(event, properties)
          if (process.env.NODE_ENV === 'development') {
            console.log('amplitude', event, properties)
          }
        }
      })
    }
  }
}

Vue.use(jusAmplitude)
