import { axiosDispatch } from '@/utils'

const disputesPath = 'api/disputes'
const consumidorPath = 'api/recepcao-consumidor-service'

const managementActions = {
  setActiveactiveOccurrency({ commit, state }, occurrency) {
    commit('setActiveOccurrency', state.activeOccurrency.id === occurrency.id ? {} : occurrency)
  },

  setSelectedIds({ commit }, ids) {
    commit('setSelectedIds', ids)
  },

  getGlobalDisputes(_, data) {
    return axiosDispatch({
      url: `${disputesPath}/management/filter`,
      method: 'POST',
      data
    })
  },

  searchViolatedRights(_, { disputeId, disputeCode }) {
    return axiosDispatch({
      url: `${consumidorPath}/v1/direitos-violados/${disputeCode}`,
      mutation: 'setDisputesViolatedRights',
      payload: disputeId
    })
  }
}

export default managementActions
