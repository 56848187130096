import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "majoracoes-container w-dvw h-dvh"
};
import IncreasesView from './views/IncreasesView.vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(IncreasesView)]);
    };
  }
});