
import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/:tab',
    name: 'Configurations',
    component: async () => await import('@/views/ConfigurationsView')
  }
]

export default routes
