export default [
  { code: 801, sigla: 'TJAC', name: 'Tribunal de Justiça do Acre' },
  { code: 802, sigla: 'TJAL', name: 'Tribunal de Justiça de Alagoas' },
  { code: 803, sigla: 'TJAP', name: 'Tribunal de Justiça do Amapá' },
  { code: 804, sigla: 'TJAM', name: 'Tribunal de Justiça do Amazonas' },
  { code: 805, sigla: 'TJBA', name: 'Tribunal de Justiça da Bahia' },
  { code: 806, sigla: 'TJCE', name: 'Tribunal de Justiça do Ceará ' },
  { code: 807, sigla: 'TJDF', name: 'Tribunal de Justiça do Distrito Federal e Territórios' },
  { code: 808, sigla: 'TJES', name: 'Tribunal de Justiça do Espírito Santo' },
  { code: 809, sigla: 'TJGO', name: 'Tribunal de Justiça de Goiás' },
  { code: 810, sigla: 'TJMA', name: 'Tribunal de Justiça do Maranhão' },
  { code: 811, sigla: 'TJMT', name: 'Tribunal de Justiça do Mato Grosso' },
  { code: 812, sigla: 'TJMS', name: 'Tribunal de Justiça do Mato Grosso do Sul' },
  { code: 813, sigla: 'TJMG', name: 'Tribunal de Justiça de Minas Gerais' },
  { code: 814, sigla: 'TJPA', name: 'Tribunal de Justiça do Pará' },
  { code: 815, sigla: 'TJPB', name: 'ribunal de Justiça da Paraíba' },
  { code: 816, sigla: 'TJPR', name: 'Tribunal de Justiça do Paraná' },
  { code: 817, sigla: 'TJPE', name: 'Tribunal de Justiça do Pernambuco' },
  { code: 818, sigla: 'TJPI', name: 'Tribunal de Justiça do Piauí' },
  { code: 819, sigla: 'TJRJ', name: 'Tribunal de Justiça do Rio de Janeiro' },
  { code: 820, sigla: 'TJRN', name: 'Tribunal de Justiça do Rio Grande do Norte' },
  { code: 821, sigla: 'TJRS', name: 'Tribunal de Justiça do Rio Grande do Sul' },
  { code: 822, sigla: 'TJRO', name: 'Tribunal de Justiça de Rondônia' },
  { code: 823, sigla: 'TJRR', name: 'Tribunal de Justiça de Roraima' },
  { code: 824, sigla: 'TJSC', name: 'Tribunal de Justiça de Santa Catarina' },
  { code: 825, sigla: 'TJSE', name: 'Tribunal de Justiça de Sergipe' },
  { code: 826, sigla: 'TJSP', name: 'Tribunal de Justiça de São Paulo' },
  { code: 827, sigla: 'TJTO', name: 'Tribunal de Justiça do Tocantins' },
  { code: 500, sigla: 'TST', name: 'Tribunal Superior do Trabalho' },
  { code: 501, sigla: 'TRT_1', name: 'TRT da 1ª Região' },
  { code: 502, sigla: 'TRT_2', name: 'TRT da 2ª Região' },
  { code: 503, sigla: 'TRT_3', name: 'TRT da 3ª Região' },
  { code: 504, sigla: 'TRT_4', name: 'TRT da 4ª Região' },
  { code: 505, sigla: 'TRT_5', name: 'TRT da 5ª Região' },
  { code: 506, sigla: 'TRT_6', name: 'TRT da 6ª Região' },
  { code: 507, sigla: 'TRT_7', name: 'TRT da 7ª Região' },
  { code: 508, sigla: 'TRT_8', name: 'TRT da 8ª Região' },
  { code: 509, sigla: 'TRT_9', name: 'TRT da 9ª Região' },
  { code: 510, sigla: 'TRT_10', name: 'TRT da 10ª Região' },
  { code: 511, sigla: 'TRT_11', name: 'TRT da 11ª Região' },
  { code: 512, sigla: 'TRT_12', name: 'TRT da 12ª Região' },
  { code: 513, sigla: 'TRT_13', name: 'TRT da 13ª Região' },
  { code: 514, sigla: 'TRT_14', name: 'TRT da 14ª Região' },
  { code: 515, sigla: 'TRT_15', name: 'TRT da 15ª Região' },
  { code: 516, sigla: 'TRT_16', name: 'TRT da 16ª Região' },
  { code: 517, sigla: 'TRT_17', name: 'TRT da 17ª Região' },
  { code: 518, sigla: 'TRT_18', name: 'TRT da 18ª Região' },
  { code: 519, sigla: 'TRT_19', name: 'TRT da 19ª Região' },
  { code: 520, sigla: 'TRT_20', name: 'TRT da 20ª Região' },
  { code: 521, sigla: 'TRT_21', name: 'TRT da 21ª Região' },
  { code: 522, sigla: 'TRT_22', name: 'TRT da 22ª Região' },
  { code: 523, sigla: 'TRT_23', name: 'TRT da 23ª Região' },
  { code: 524, sigla: 'TRT_24', name: 'TRT da 24ª Região' },
  { code: 401, sigla: 'TRF_1', name: 'Brasília (TRF da 1ª Região)' },
  { code: 402, sigla: 'TRF_2', name: 'Rio de Janeiro (TRF da 2ª Região)' },
  { code: 403, sigla: 'TRF_3', name: 'São Paulo (TRF da 3ª Região)' },
  { code: 404, sigla: 'TRF_4', name: 'Porto Alegre (TRF da 4ª Região)' },
  { code: 405, sigla: 'TRF_5', name: 'Recife (TRF da 5ª Região)' },
  { code: 590, sigla: 'CSJT', name: 'Conselho Superior da Justiça do Trabalho' }
]
