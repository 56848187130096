import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "majoracao-container bg-white rounded-lg shadow-md m-4"
};
const _hoisted_2 = {
  class: "p-6 pt-0"
};
const _hoisted_3 = {
  class: "text-xl font-semibold text-gray-800 mb-6"
};
const _hoisted_4 = {
  class: "flex flex-row gap-4 mb-6 justify-between flex-wrap"
};
const _hoisted_5 = {
  class: "text-gray-800"
};
const _hoisted_6 = {
  class: "text-gray-800"
};
const _hoisted_7 = {
  class: "flex flex-row justify-between flex-wrap gap-4 mb-6"
};
const _hoisted_8 = {
  class: "flex-1"
};
const _hoisted_9 = {
  class: "text-gray-800"
};
const _hoisted_10 = {
  class: "flex-1"
};
const _hoisted_11 = {
  class: "text-gray-800"
};
const _hoisted_12 = {
  class: "flex-1"
};
const _hoisted_13 = {
  class: "text-blue-600"
};
const _hoisted_14 = {
  class: "text-sm"
};
const _hoisted_15 = {
  class: "flex items-center gap-2"
};
const _hoisted_16 = {
  class: "text-blue-600"
};
const _hoisted_17 = {
  class: "flex flex-row justify-between flex-wrap gap-4 mb-6"
};
const _hoisted_18 = {
  class: "text-gray-800"
};
const _hoisted_19 = {
  class: "text-gray-800"
};
const _hoisted_20 = {
  class: "mb-6"
};
const _hoisted_21 = {
  class: "text-gray-800"
};
const _hoisted_22 = {
  class: "mb-6"
};
const _hoisted_23 = ["disabled"];
const _hoisted_24 = {
  key: 1,
  class: "flex flex-row flex-wrap gap-4"
};
const _hoisted_25 = ["disabled"];
const _hoisted_26 = {
  key: 2
};
const _hoisted_27 = {
  key: 0,
  class: "p-4 mb-4 text-base text-center text-red-800 rounded-lg bg-red-200",
  role: "alert"
};
const _hoisted_28 = {
  key: 1,
  class: "p-4 mb-4 text-base text-center text-green-800 rounded-lg bg-green-200",
  role: "alert"
};
const _hoisted_29 = {
  key: 2,
  class: "p-4 mb-4 text-base text-center text-yellow-800 rounded-lg bg-yellow-200",
  role: "alert"
};
import { ref, computed } from 'vue';
import { format } from 'date-fns';
export default /*@__PURE__*/_defineComponent({
  __name: 'MajoracaoBase',
  props: {
    "value": {
      type: Object,
      required: true
    },
    "valueModifiers": {}
  },
  emits: /*@__PURE__*/_mergeModels(["aprovar", "rejeitar"], ["update:value"]),
  setup(__props, {
    emit: __emit
  }) {
    const majoracao = _useModel(__props, 'value');
    const emit = __emit;
    const valorAutorizado = ref(majoracao.value?.approvedValue ?? majoracao.value.requestedValue ?? 0);
    const observacoes = ref(majoracao.value?.observation ?? '');
    const moneyConfig = {
      prefix: 'R$ ',
      decimal: ',',
      thousands: '.',
      precision: 2
    };
    const id = computed(() => majoracao.value.disputeId);
    const numeroProcesso = computed(() => majoracao.value.processNumber);
    const nomeParteContraria = computed(() => majoracao.value.plaintiffName);
    const valorOriginal = computed(() => majoracao.value.originalValue);
    const valorSolicitado = computed(() => majoracao.value.requestedValue);
    const nomeSolicitante = computed(() => majoracao.value.negotiatorName);
    const dataSolicitacao = computed(() => format(new Date(majoracao.value.requestedAt), 'dd/MM/yyyy \'às\' HH:mm'));
    const justificativa = computed(() => majoracao.value.justiticationRequest);
    const diferenca = computed(() => valorSolicitado.value - valorOriginal.value);
    const percentualDiferenca = computed(() => diferenca.value / valorOriginal.value);
    const percentualAutorizado = computed(() => {
      const valor = Number(valorAutorizado.value);
      if (isNaN(valor)) return 0;
      return (valor - valorOriginal.value) / valorOriginal.value;
    });
    function formatPercent(value) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'percent',
        maximumFractionDigits: 2,
        signDisplay: 'always'
      }).format(value);
    }
    function formatCurrency(value) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(value);
    }
    const aprovar = () => {
      emit('aprovar', {
        approvedValue: valorAutorizado.value,
        observation: observacoes.value
      });
    };
    const rejeitar = () => {
      emit('rejeitar', {
        approvedValue: 0,
        observation: observacoes.value
      });
    };
    return (_ctx, _cache) => {
      const _component_money3 = _resolveComponent("money3");
      return _openBlock(), _createElementBlock("article", _hoisted_1, [_cache[15] || (_cache[15] = _createElementVNode("section", {
        class: "text-center mb-4 bg-lime-50 p-2"
      }, [_createElementVNode("span", {
        class: "text-green-600 text-sm flex items-center justify-center gap-1"
      }, [_createElementVNode("svg", {
        class: "w-6 h-6 text-green-600",
        "aria-hidden": "true",
        xmlns: "http://www.w3.org/2000/svg",
        width: "24",
        height: "24",
        fill: "currentColor",
        viewBox: "0 0 24 24"
      }, [_createElementVNode("path", {
        "fill-rule": "evenodd",
        d: "M8 10V7a4 4 0 1 1 8 0v3h1a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h1Zm2-3a2 2 0 1 1 4 0v3h-4V7Zm2 6a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1Z",
        "clip-rule": "evenodd"
      })]), _createTextVNode(" Link seguro de aprovação ")])], -1)), _createElementVNode("section", _hoisted_2, [_createElementVNode("h1", _hoisted_3, " Solicitação de Majoração #" + _toDisplayString(id.value), 1), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", null, [_cache[2] || (_cache[2] = _createElementVNode("label", {
        class: "text-sm text-gray-600"
      }, "Número do processo:", -1)), _createElementVNode("p", _hoisted_5, _toDisplayString(numeroProcesso.value), 1)]), _createElementVNode("div", null, [_cache[3] || (_cache[3] = _createElementVNode("label", {
        class: "text-sm text-gray-600"
      }, "Nome da parte contrária:", -1)), _createElementVNode("p", _hoisted_6, _toDisplayString(nomeParteContraria.value), 1)])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_cache[4] || (_cache[4] = _createElementVNode("label", {
        class: "text-sm text-gray-600"
      }, "Valor original:", -1)), _createElementVNode("p", _hoisted_9, _toDisplayString(formatCurrency(valorOriginal.value)), 1)]), _createElementVNode("div", _hoisted_10, [_cache[5] || (_cache[5] = _createElementVNode("label", {
        class: "text-sm text-gray-600"
      }, "Valor solicitado:", -1)), _createElementVNode("p", _hoisted_11, _toDisplayString(formatCurrency(valorSolicitado.value)), 1)]), _createElementVNode("div", _hoisted_12, [_cache[6] || (_cache[6] = _createElementVNode("label", {
        class: "text-sm text-gray-600"
      }, "Diferença:", -1)), _createElementVNode("p", _hoisted_13, [_createTextVNode(_toDisplayString(formatCurrency(diferenca.value)) + " ", 1), _createElementVNode("span", _hoisted_14, "(" + _toDisplayString(formatPercent(percentualDiferenca.value)) + ")", 1)])])]), ['CANCELED', 'REJECTED'].includes(majoracao.value.status) === Boolean() ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["bg-blue-50 p-4 rounded-lg mb-6", {
          'bg-blue-50': percentualAutorizado.value < 0.15,
          'bg-orange-50': percentualAutorizado.value >= 0.15 && percentualAutorizado.value < 0.2,
          'bg-red-50': percentualAutorizado.value >= 0.2
        }])
      }, [_cache[7] || (_cache[7] = _createElementVNode("label", {
        class: "text-sm text-gray-600"
      }, "Valor de majoração autorizado:", -1)), _createElementVNode("div", _hoisted_15, [_createVNode(_component_money3, _mergeProps({
        modelValue: valorAutorizado.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => valorAutorizado.value = $event),
        modelModifiers: {
          number: true
        }
      }, moneyConfig, {
        disabled: majoracao.value.status !== 'WAITING',
        class: [{
          'cursor-not-allowed': majoracao.value.status !== 'WAITING'
        }, "w-full p-2 border border-blue-200 rounded focus:outline-none focus:border-blue-400"]
      }), null, 16, ["modelValue", "disabled", "class"]), _createElementVNode("span", _hoisted_16, "(" + _toDisplayString(formatPercent(percentualAutorizado.value)) + ")", 1)])], 2)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_17, [_createElementVNode("div", null, [_cache[8] || (_cache[8] = _createElementVNode("label", {
        class: "text-sm text-gray-600"
      }, "Nome do solicitante:", -1)), _createElementVNode("p", _hoisted_18, _toDisplayString(nomeSolicitante.value), 1)]), _createElementVNode("div", null, [_cache[9] || (_cache[9] = _createElementVNode("label", {
        class: "text-sm text-gray-600"
      }, "Data da solicitação:", -1)), _createElementVNode("p", _hoisted_19, _toDisplayString(dataSolicitacao.value), 1)])]), _createElementVNode("div", _hoisted_20, [_cache[10] || (_cache[10] = _createElementVNode("label", {
        class: "text-sm text-gray-600"
      }, "Justificativa da solicitação:", -1)), _createElementVNode("p", _hoisted_21, _toDisplayString(justificativa.value), 1)]), _createElementVNode("div", _hoisted_22, [_cache[11] || (_cache[11] = _createElementVNode("label", {
        class: "text-sm text-gray-600"
      }, "Observações:", -1)), _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => observacoes.value = $event),
        class: _normalizeClass(["w-full p-3 border border-gray-200 rounded resize-none focus:outline-none focus:border-blue-400", {
          'cursor-not-allowed': majoracao.value.status !== 'WAITING'
        }]),
        rows: "4",
        disabled: majoracao.value.status !== 'WAITING'
      }, null, 10, _hoisted_23), [[_vModelText, observacoes.value]])]), majoracao.value.status === 'WAITING' ? (_openBlock(), _createElementBlock("div", _hoisted_24, [_createElementVNode("button", {
        class: _normalizeClass(["flex-1 min-w-48 bg-emerald-500 hover:bg-emerald-600 text-white py-2 px-4 rounded transition-colors", {
          'cursor-not-allowed bg-emerald-300': percentualAutorizado.value <= 0.0
        }]),
        disabled: percentualAutorizado.value <= 0.0,
        onClick: aprovar
      }, _toDisplayString(valorSolicitado.value === valorAutorizado.value ? 'Aprovar majoração' : 'Alterar alçada'), 11, _hoisted_25), _createElementVNode("button", {
        class: "flex-1 min-w-48 bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded transition-colors",
        onClick: rejeitar
      }, " Rejeitar majoração ")])) : (_openBlock(), _createElementBlock("div", _hoisted_26, [majoracao.value.status === 'REJECTED' ? (_openBlock(), _createElementBlock("div", _hoisted_27, _cache[12] || (_cache[12] = [_createElementVNode("span", {
        class: "font-bold"
      }, "Solicitação rejeitada!", -1)]))) : majoracao.value.status === 'APPROVED' ? (_openBlock(), _createElementBlock("div", _hoisted_28, _cache[13] || (_cache[13] = [_createElementVNode("span", {
        class: "font-bold"
      }, "Solicitação aprovada!", -1)]))) : majoracao.value.status === 'CANCELED' ? (_openBlock(), _createElementBlock("div", _hoisted_29, _cache[14] || (_cache[14] = [_createElementVNode("span", {
        class: "font-bold"
      }, "Solicitação cancelada!", -1)]))) : _createCommentVNode("", true)]))])]);
    };
  }
});