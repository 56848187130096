import TicketOverviewPartySortItem from '@/models/negotiations/overview/TicketOverviewPartySortItem'

const overviewGetters = {
  getTicketOverview: ({ ticketOverview }) => ticketOverview,
  getTicketOverviewInfo: state => state.ticketOverviewInfo,
  getTicketOverviewProconInfo: state => state.ticketOverviewProconInfo,
  getTicketOverviewParties: state => {
    return state.ticketOverviewParties.sort((a, b) => {
      const roleA = new TicketOverviewPartySortItem(a)
      const roleB = new TicketOverviewPartySortItem(b)

      return (roleA.polarityWeight + roleA.rolesWeight) - (roleB.polarityWeight + roleB.rolesWeight)
    })
  },
  getTicketOverviewPartiesArchived: ({ ticketOverviewPartiesArchived }) => ticketOverviewPartiesArchived ?? [],
  getLastTicketOffers: ({ lastTicketOffers }) => lastTicketOffers,
  getTicketOverviewProperties: state => state.ticketOverviewProperties,
  getTicketOverviewAttachments: ({ ticketOverviewAttachments }) => ticketOverviewAttachments,
  isTicketOverviewloading: ({ ticketOverviewCountGetters }) => ticketOverviewCountGetters > 0,
  getTicketMetadata: state => state.ticketMetadata,
  getTicketOverviewStatus: state => state.ticketOverview?.status ?? '',
  AssociatedContactsPropertie: state => state.haveAssociatedContacts,
  showAssociatedContacts: state => {
    return (['IMPORTED', 'ENGAGEMENT', 'PENDING'].includes(state.ticketOverview.status) &&
      Boolean(state.haveAssociatedContacts) &&
      ['NAO'].includes(state.haveAssociatedContacts))
  },
  getTicketOverviewNegotiators: ({ ticketOverviewParties = [] }) => ticketOverviewParties.filter(({ roles }) => roles.includes('NEGOTIATOR')),
  getTicketOverviewClaimants: state => (state.ticketOverviewParties ?? []).filter(({ polarity }) => (polarity === 'CLAIMANT')),
  getTicketOverviewIncreases: state => state.ticketOverviewIncreases.sort((a, b) => {
    if (a.status === 'WAITING' && b.status !== 'WAITING') return -1
    if (a.status !== 'WAITING' && b.status === 'WAITING') return 1

    return new Date(b.requestedAt) - new Date(a.requestedAt)
  })
}

export default overviewGetters
