import { createFetch } from '@vueuse/core'

const useFetch = createFetch({
  baseUrl: process.env.VUE_APP_API_BASE_URL,
  options: {
    async beforeFetch ({ options }) {
      options.headers = {
        accept: 'application/json;charset=UTF-8, text/plain, */*',
        ...options.headers
      }

      return { options }
    }
  }
})

export default useFetch
