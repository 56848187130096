import REGEX from '@/constants/passwordValidateRegex'

const validatePasswordSecurity = (rule, value, callback) => {
  console.log('validatePasswordSecurity', value)
  if (value && REGEX.test(value)) {
    callback()
  } else {
    callback(new Error())
  }
}

export default validatePasswordSecurity
