import { normalizeString } from '@/utils'

const documentGetters = {
  availableSigners: state => state.availableSigners,
  selectedSigners: state => [...state.selectedSigners],
  getStoredDrafts: state => state.storedDrafts,
  getStoredDraftsIds: state => Object.values(state.storedDrafts).map(({ id, disputeId }) => (id || disputeId)),
  getOpenDraftId: state => state.openDraftId,
  getSignerServiceAvailable: state => state.signerService?.enabled,
  getAvailableProcons: state => state.availableProcons,
  getProconIsSupported: state => (tipoProcon) => state.availableProcons.some(name => (normalizeString(tipoProcon) !== '' && normalizeString(name) === normalizeString(tipoProcon)))
}

export default documentGetters
