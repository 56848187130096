import useFetch from '@/utils/fetch'
import { Ref } from 'vue'
import { CreateIncreasePayload, Majoracao } from './models'
import { buildQuery } from '@/utils'

const apiPath = String(process.env.VUE_APP_API_BASE_URL)
const disputePath = `${apiPath}/api/disputes`
const majoracaoPath = `${disputePath}/v2/increase`

async function searchIncreaseByCode (code: string, query: Record<string, string>): Promise<Ref<Majoracao>> {
  const response = await useFetch(`${majoracaoPath}/code/${code}?${buildQuery(query)}`).get().json()

  return response.data
}

async function createIncrease (code: string, query: Record<string, string>, payload: CreateIncreasePayload): Promise<Ref<Majoracao>> {
  await useFetch(`${majoracaoPath}/code/${code}?${buildQuery(query)}`).post(payload).json()

  return await searchIncreaseByCode(code, query)
}

export {
  searchIncreaseByCode,
  createIncrease
}
