<template>
  <el-config-provider namespace="todo">
    <TodoList />
  </el-config-provider>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'
const TodoList = defineAsyncComponent(async () => await import('./views/TodoList'))
</script>

<style lang="scss">
  [id^="el-popper-container-"] {
    .todo-list__container:not(:last-child) {
      display: none !important;
    }
  }
</style>
