export default {
  templates: {
    status: 'unloaded',
    templates: []
  },

  wallet: {
    balance: 0,
    status: 'unloaded',
    message: null
  },

  appNames: ['JUSTTO', 'DGRADVOGADOS', 'MascarenhasBarbosa', 'Menezes', 'JbmAdvogados', 'WerutskyCardosoBritoAdvogados', 'OlimpioAzevedo', 'KGSAdvogados', 'HelperGestao', 'LIMAEFALCAO', 'ImoveisVillani', 'CoopLivreSicoob', 'BernardiSchnapp', 'MEDIATO', 'Baptista', 'ProjurisAcordos', 'VillemorGol', 'PraxisAssessoria', 'ProSolutti', 'Impacta2', 'QuantumAtivosJudiciais', 'AcordoPge', 'AlbuquerqueMelo', 'CollectorCobranca', 'DeltaLife', 'CarvalhoRodrigues'/* 'AndreDeSa', 'CostaeVieira', 'Crefisa' */]
}
