const managementGetters = {
  activeOccurrency: state => state.activeOccurrency,
  getSelectedIds: state => state.selectedIds,
  getUnselectedIds: state => state.unselectedIds,
  getIsAllIdsSelected: (state, getters) => {
    return state.selectedIds.length === (getters?.disputes ?? []).length
  },
  getLoadedViolatedRightsDisputeIds: state => Object.keys(state.disputesViolatedRights).map(Number),
  getMappedViolatedRights: state => state.disputesViolatedRights
}

export default managementGetters
