
import ptBr from './pt-br'
import en from './en'

const messages = {
  'pt-br': ptBr,
  en
}

export default messages
