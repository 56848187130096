import moment from 'moment'

import { approximateDate } from '@/utils'

const isToday = (date) => moment(date).isSame(moment(), 'day')

const gettersNotifications = {
  notifications: (state) => state.notifications,
  mentionNotifications: (state) => state.mentionNotifications,
  mentionNotificationsGroupped: ({ mentionNotifications }) => {
    return mentionNotifications.content.reduce((acc, mention) => {
      const date = approximateDate(mention.createdAt.split('T')[0])

      acc[date] = [...(acc[date] || []), mention]
      return acc
    }, {})
  },
  mentionNotificationsFilter: state => ({
    page: state.mentionNotifications.pageable.pageNumber + 1,
    read: state.seeMentionsReaded
  }),
  mentionsOnlyRead: (state) => state.seeMentionsReaded,
  notificationsNotEmptyDisputes: ({ notifications }) => notifications.filter(({ quantity }) => quantity > 0),
  qtdThamirisPendingAlerts: ({ notifications }) => notifications.filter(({ quantity }) => quantity > 0).length,
  qtdMentionsPending: state => Number(state.mentionNotificationsSummary.unread),
  qtdNotifications: (state, getters) => {
    return (state.notifications.filter(({ quantity }) => quantity > 0).length || 0) + getters.qtdMentionsPending
  },
  areNotificationsVisible: state => state.notificationsVisible,
  notificationHoursDiff: () => {
    return moment().diff(moment(localStorage.getItem('jusAlertsLastView')), 'hours')
  },
  qtdBatchActionsProgressErros: state => state.batchActionsProgress.filter(({ falhas, dataHoraSolicitacao }) => (falhas > 0 && isToday(dataHoraSolicitacao))).length,
  getBatchActionsProgress: state => state.batchActionsProgress
}

export default gettersNotifications
