import * as cpf from '@fnando/cpf'

const stripDoc = (doc) => {
  if (cpf.isValid(doc)) {
    return cpf.strip(doc)
  } else {
    return ''
  }
}

export default stripDoc
