import Vue from 'vue'

export default {
  setTemplates(state, templates) {
    Vue.set(state, 'templates', templates)
  },

  setWalletStatus(state, status) {
    Vue.set(state, 'wallet', status)
  },

  setAppNames(state, appNames) {
    Vue.set(state, 'appNames', appNames)
  }
}
