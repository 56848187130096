<template>
  <img
    v-if="iconPath"
    :src="iconPath"
    :style="{ width: size, display: 'inline' }"
    alt=""
    draggable="false"
    :class="{ 'inverted': white }"
    @click="$emit('click')"
    @mouseover="$emit('hover')"
  >
</template>

<script>
export default {
  name: 'JusIcon',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },

    isWhite: {
      type: Boolean,
      default: false
    },

    white: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: 'ic'
    },

    svgFamily: {
      type: String,
      default: 'default'
    },

    icon: {
      type: String,
      default: ''
    },

    size: {
      type: String,
      default: ''
    },

    path: {
      type: String,
      default: 'icons'
    }
  },

  computed: {
    iconPath: function() {
      if (this.icon) {
        if (this.svgFamily === 'default') {
          return `https://assets.acordo.app/${this.path}/ic-${this.icon}${(this.isActive ? '-active' : '')}${(this.isWhite ? '-white' : '')}.svg`
        } else {
          return `https://assets.acordo.app/${this.path}/${this.icon}.svg`
        }
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  &.inverted {
    filter: invert(1);
  }
}
</style>
