const ptBr = {
  tabs: {
    team: 'Equipe',
    featuresAndModules: 'Funcionalidades e módulos',
    workspaceData: 'Dados da conta'
  },
  featuresAndModules: {
    searchPlaceholder: 'Busque pela configuração que deseja, ex: "minuta" ou "ofensores"',
    disabledFeature: 'O recurso não está habilitado!',
    configure: 'Configurar',
    coust: 'Gratis | Cobrado',
    PRE_NEGOTIATION: {
      label: 'pré-negociação',
      icon: 'lighthouse'
    },
    BAD_FAITH_LITIGANT: {
      label: 'detecção de ofensores',
      icon: 'people-documentation'
    },
    AUTOMATIC_MESSAGES: {
      label: 'automação de mensagens',
      icon: 'automatic-massage'
    },
    DRAFT_MANAGEMENT: {
      label: 'gestão de minutas',
      icon: 'protocol'
    },
    COMMUNICATION_BLOCK_LIST: {
      label: 'lista de bloqueio de endereços',
      icon: 'locked-board'
    },
    API_INTEGRATION: {
      label: 'integração em API para importação',
      icon: 'power-plug'
    },
    CUSTOMIZED_CONFIGURATIONS: {
      label: 'sua empresa/escritório como ODR',
      icon: 'customized-configurations'
    },
    NET_PROMOTER_SCORE: {
      label: 'net promoter score',
      icon: 'net-promoter-score-config'
    },
    CUSTOMIZE_ODR_ADDRESS: {
      label: 'ODR',
      icon: 'customized-configurations'
    },
    CLOSING_REASONS: {
      label: 'Motivos de encerramento',
      icon: 'negative-hand'
    },
    DRAFT_NOTIFICATIONS: {
      label: 'Notificar minutagem',
      icon: 'accepted'
    },
    IMPORTING_CASES: {
      label: 'Importação de casos',
      icon: 'upload-file'
    },
    BANKING_INSTITUTIONS: {
      label: 'Instituições bancarias',
      icon: 'banking-institution'
    },
    PROCESS_CAPTURE: {
      label: 'Captura de processos nos tribunais',
      icon: 'chalkboard-user'
    },
    ATTACHMENT_REQUEST: {
      label: 'Solicitar Petição Inicial',
      icon: 'file-invoice'
    },
    automaticMessages: {
      NOTIFY_UNANSWERED_EMAIL: 'Notificar parte via e-mail após 36 horas sem resposta.',
      NOTIFY_UNANSWERED_WHATSAPP: 'Notificar parte após 23 horas sem resposta no WhatsApp.',
      NOTIFY_OUT_OF_BUSINESS_HOURS: 'Notificar parte sobre o horário de atendimento.',
      SEND_COUNTEROFFER: 'Registrar contraproposta automaticamente.',
      SEND_DISPUTE_INFO_REPLY: 'Responder parte com informações sobre a disputa.',
      DISPUTE_UNDER_ANALYSIS_REPLY: 'Resposta automática às disputas em análise pela empresa.',
      SEND_NPS_SURVEY: 'Enviar formulário para avalição de NPS.',
      NOTIFY_DISPUTE_UNDER_ANALYSIS: 'Responder automaticamente disputas em analise pela empresa.',
      NOTIFY_DISPUTE_NEGOTATION_PAUSED: 'Resposta automática às disputas suspensas(pausadas).',
      NOTIFY_DISPUTE_NEGOTIATION_DONE: 'Resposta automática às disputas encerradas.',
      NOTIFY_UNANSWERED_DISPUTE: 'Envio de e-mail para disputas sem resposta.',
      SEND_SCAM_CLAIM_REPLY: 'Responder a parte informando legitimidade do contato.',
      DISABLE_DRAFT_NOT_SIGNED_NOTIFICATION: 'Após 48 horas do envio para assinatura, se não tiver concluído (todas as assinaturas), então deve alertar o(a) negociador(a).',
      DISABLE_DRAFT_NOT_CREATED_NOTIFICATION: 'Após 24 horas do aceite da disputa, se ainda não tiver gerado minuta, então deve alertar o(a) negociador(a).',
      DISABLE_DRAFT_NOT_SENT_NOTIFICATION: 'Após 24 horas da geração da minuta, se o(a) negociador(a) não enviar para assinatura, então deve alertar.',
      USE_AUTO_SETTLE: 'Ganhar automáticamente a disputa, quando a minuta for assinada.',
      ALWAYS: 'Sempre',
      CUSTOM: 'Personalizado'
    },
    authorization: {
      type: {
        API_KEY: 'Autenticação por Chave da API',
        BEARER_TOKEN: 'Autenticação por Token',
        BASIC_AUTH: 'Autenticação Básica'
      }
    }
  },
  'integration-types': {
    PROJURIS_SOAP: 'Projuris Empresas',
    FINCH: 'Webservices da finch',
    JUSTTO_WEBHOOK: 'Webhook padrão do Projuris Acordos'
  },
  'reports-types': {
    INACTIVE: 'Desabilitado',
    UNKNOWN: 'Desabilitado',
    DAILY: 'Diário',
    WEEKLY: 'Semanal',
    FORTNIGHT: 'Quinzenal',
    MONTHLY: 'Mensal'
  },
  'profile-types': {
    ADMINISTRATOR: 'Administrador',
    REVISOR: 'Revisor',
    NEGOTIATOR: 'Negociador'
  },
  'profile-status': {
    active: 'Ativo',
    inactive: 'Inativo',
    undefined: 'Não definido'
  },
  boolean: 'Sim | Não',
  INDENIZATORIO: {
    dispute: {
      code: 'Processo',
      externalId: 'Código interno',
      claimantParty: 'Parte contrária',
      respondentParty: 'Réu',
      claimantLawyer: 'Advogado da parte',
      upperRange: 'Alçada máxima',
      counterOfferValue: 'Proposta da parte',
      expirationDate: 'Fim da negociação',
      roles: {
        PARTY: {
          RESPONDENT: 'réu',
          CLAIMANT: 'autor',
          UNKNOWN: 'parte desconhecida'
        },
        LAWYER: {
          RESPONDENT: 'advogado do réu',
          CLAIMANT: 'advogado do autor',
          UNKNOWN: 'advogado desconhecido'
        },
        NEGOTIATOR: {
          RESPONDENT: 'negociador',
          CLAIMANT: 'negociador',
          UNKNOWN: 'negociador desconhecido'
        },
        REVISOR: {
          RESPONDENT: 'revisor',
          CLAIMANT: 'revisor',
          UNKNOWN: 'revisor desconhecido'
        },
        OWN_CAUSE: {
          RESPONDENT: 'advogado em causa própria',
          CLAIMANT: 'advogado em causa própria',
          UNKNOWN: 'causa própria'
        },
        UNKNOWN: {
          RESPONDENT: 'função indefinida',
          CLAIMANT: 'função indefinida',
          UNKNOWN: 'função indefinida'
        }
      },
      fields: {
        claimantParty: 'Parte contrária',
        claimantParties: 'Partes contrárias',
        claimantLawyer: 'Advogado da parte',
        claimantLawyers: 'Advogados da parte',
        claimantProposal: 'Proposta da Parte',
        dispute: 'disputa',
        respondentParty: 'Réu',
        respondentParties: 'Réus',
        respondentLawyer: 'Advogado do Réu',
        respondentLawyers: 'Advogados do Réu',
        respondentNegotiator: 'Negociador',
        impartialParty: 'Arbitro'
      }
    },
    workspace: {
      description: 'Indenizatório',
      icon: 'coins'
    },
    configuration: {
      'pre-negotiation': {
        terms: {
          term: 'Termos a serem detectadas para classificar como pré-negociação.',
          defendantPersonName: 'Advogados do réu a serem detectadas para classificar como pré-negociação.',
          plaintiffPersonName: 'Autor e Advogado do autor a serem detectados para classificar como pré-negociação.',
          plaintiffOabLicense: 'OABs a serem detectadas para classificar como pré-negociação.'
        }
      }
    }
  },
  RECOVERY: {
    dispute: {
      code: 'Processo',
      externalId: 'Código interno',
      claimantParty: 'Réu',
      respondentParty: 'Empresa',
      claimantLawyer: 'Advogado do Réu',
      upperRange: 'Valor limite',
      counterOfferValue: 'Proposta do réu',
      expirationDate: 'Fim da negociação',
      roles: {
        PARTY: {
          RESPONDENT: 'empresa',
          CLAIMANT: 'réu',
          UNKNOWN: 'parte desconhecida'
        },
        LAWYER: {
          RESPONDENT: 'advogado da empresa',
          CLAIMANT: 'advogado do réu',
          UNKNOWN: 'advogado desconhecido'
        },
        NEGOTIATOR: {
          RESPONDENT: 'negociador',
          CLAIMANT: 'negociador',
          UNKNOWN: 'negociador desconhecido'
        },
        REVISOR: {
          RESPONDENT: 'revisor',
          CLAIMANT: 'revisor',
          UNKNOWN: 'revisor desconhecido'
        },
        OWN_CAUSE: {
          RESPONDENT: 'advogado em causa própria',
          CLAIMANT: 'advogado em causa própria',
          UNKNOWN: 'causa própria'
        },
        UNKNOWN: {
          RESPONDENT: 'função indefinida',
          CLAIMANT: 'função indefinida',
          UNKNOWN: 'função indefinida'
        }
      },
      fields: {
        claimantParty: 'Réu',
        claimantParties: 'Réus',
        claimantLawyer: 'Advogado do Réu',
        claimantLawyers: 'Advogados do Réu',
        claimantProposal: 'Proposta do Réu',
        dispute: 'disputa',
        respondentParty: 'Empresa',
        respondentParties: 'Empresas',
        respondentLawyer: 'Advogado da Empresa',
        respondentLawyers: 'Advogados da Empresa',
        respondentNegotiator: 'Negociador',
        impartialParty: 'Arbitro'
      }
    },
    workspace: {
      description: 'Cobrança',
      icon: 'exchange'
    },
    configuration: {
      'pre-negotiation': {
        terms: {
          term: 'Termos a serem detectadas para classificar como pré-negociação.',
          defendantPersonName: 'Advogados da empresa a serem detectadas para classificar como pré-negociação.',
          plaintiffPersonName: 'Réu e Advogados do réu a serem detectados para classificar como pré-negociação.',
          plaintiffOabLicense: 'OABs a serem detectadas para classificar como pré-negociação.'
        }
      }
    }
  },
  PROCURADORIA: {
    dispute: {
      code: 'Número da CDA',
      externalId: 'Código interno',
      claimantParty: 'Corresponsável',
      respondentParty: 'Procuradoria',
      claimantLawyer: 'Devedor',
      upperRange: 'Saldo',
      counterOfferValue: 'Proposta do Devedor',
      expirationDate: 'Data limite',
      roles: {
        PARTY: {
          RESPONDENT: 'Procuradoria',
          CLAIMANT: 'Corresponsável',
          UNKNOWN: 'parte desconhecida'
        },
        LAWYER: {
          RESPONDENT: 'advogado da procuradoria',
          CLAIMANT: 'Devedor',
          UNKNOWN: 'advogado desconhecido'
        },
        NEGOTIATOR: {
          RESPONDENT: 'negociador',
          CLAIMANT: 'negociador',
          UNKNOWN: 'negociador desconhecido'
        },
        REVISOR: {
          RESPONDENT: 'revisor',
          CLAIMANT: 'revisor',
          UNKNOWN: 'revisor desconhecido'
        },
        OWN_CAUSE: {
          RESPONDENT: 'advogado em causa própria',
          CLAIMANT: 'Devedor em causa própria',
          UNKNOWN: 'causa própria'
        },
        UNKNOWN: {
          RESPONDENT: 'função indefinida',
          CLAIMANT: 'função indefinida',
          UNKNOWN: 'função indefinida'
        }
      },
      fields: {
        claimantParty: 'Corresponsável',
        claimantParties: 'Corresponsávels',
        claimantLawyer: 'Devedor',
        claimantLawyers: 'Devedores',
        claimantProposal: 'Proposta do Devedor',
        dispute: 'disputa',
        respondentParty: 'Procuradoria',
        respondentParties: 'Procuradorias',
        respondentLawyer: 'Advogado da Procuradoria',
        respondentLawyers: 'Advogados da Procuradoria',
        respondentNegotiator: 'Negociador',
        impartialParty: 'Arbitro'
      }
    },
    workspace: {
      description: 'Procuradoria',
      icon: 'email-cna'
    },
    configuration: {
      'pre-negotiation': {
        terms: {
          term: 'Termos a serem detectadas para classificar como pré-negociação.',
          defendantPersonName: 'Advogados da empresa a serem detectadas para classificar como pré-negociação.',
          plaintiffPersonName: 'Réu e Advogados do réu a serem detectados para classificar como pré-negociação.',
          plaintiffOabLicense: 'OABs a serem detectadas para classificar como pré-negociação.'
        }
      }
    }
  },
  PROCON_CONSUMIDOR: {
    dispute: {
      code: 'Processo',
      externalId: 'Código interno',
      claimantParty: 'Parte contrária',
      respondentParty: 'Réu',
      claimantLawyer: 'Advogado da parte',
      upperRange: 'Alçada máxima',
      counterOfferValue: 'Proposta da parte',
      expirationDate: 'Fim da negociação',
      roles: {
        PARTY: {
          RESPONDENT: 'réu',
          CLAIMANT: 'autor',
          UNKNOWN: 'parte desconhecida'
        },
        LAWYER: {
          RESPONDENT: 'advogado do réu',
          CLAIMANT: 'advogado do autor',
          UNKNOWN: 'advogado desconhecido'
        },
        NEGOTIATOR: {
          RESPONDENT: 'negociador',
          CLAIMANT: 'negociador',
          UNKNOWN: 'negociador desconhecido'
        },
        REVISOR: {
          RESPONDENT: 'revisor',
          CLAIMANT: 'revisor',
          UNKNOWN: 'revisor desconhecido'
        },
        OWN_CAUSE: {
          RESPONDENT: 'advogado em causa própria',
          CLAIMANT: 'advogado em causa própria',
          UNKNOWN: 'causa própria'
        },
        UNKNOWN: {
          RESPONDENT: 'função indefinida',
          CLAIMANT: 'função indefinida',
          UNKNOWN: 'função indefinida'
        }
      },
      fields: {
        claimantParty: 'Parte contrária',
        claimantParties: 'Partes contrárias',
        claimantLawyer: 'Advogado da parte',
        claimantLawyers: 'Advogados da parte',
        claimantProposal: 'Proposta da Parte',
        dispute: 'disputa',
        respondentParty: 'Réu',
        respondentParties: 'Réus',
        respondentLawyer: 'Advogado do Réu',
        respondentLawyers: 'Advogados do Réu',
        respondentNegotiator: 'Negociador',
        impartialParty: 'Arbitro'
      }
    },
    workspace: {
      description: 'Indenizatório',
      icon: 'coins'
    },
    configuration: {
      'pre-negotiation': {
        terms: {
          term: 'Termos a serem detectadas para classificar como pré-negociação.',
          defendantPersonName: 'Advogados do réu a serem detectadas para classificar como pré-negociação.',
          plaintiffPersonName: 'Autor e Advogado do autor a serem detectados para classificar como pré-negociação.',
          plaintiffOabLicense: 'OABs a serem detectadas para classificar como pré-negociação.'
        }
      }
    }
  }
}

export default ptBr
