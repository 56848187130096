import { axiosDispatch } from '@/utils'

const strategiesPath = 'api/strategies/v1'
const gerenciadorTemplate = 'api/gerenciador-template'

const strategyActions = {
  getMyStrategiesLite() {
    return axiosDispatch({
      url: `${strategiesPath}`,
      mutation: 'setImportedStrategiesLite'
    })
  },

  getStrategyEngagementLite(_, strategyId) {
    return axiosDispatch({
      url: `${strategiesPath}/${strategyId}/communications`,
      mutation: 'setStrategyCommunication',
      payload: { strategyId }
    })
  },

  getStrategyCommunicationTemplate(_, { strategyId, comunicationId }) {
    return axiosDispatch({
      url: `${strategiesPath}/${strategyId}/communications/${comunicationId}/template`,
      mutation: 'setStrategyCommunicationTemplate',
      payload: { strategyId, comunicationId }
    })
  },

  editStrategyTemplate(_, params) {
    return axiosDispatch({
      url: 'api/templates/',
      method: 'PUT',
      data: params
    })
  },

  createStrategyTemplate(_, params) {
    return axiosDispatch({
      url: 'api/templates/',
      method: 'POST',
      data: params
    })
  },

  getStrategyTemplate(_, templateId) {
    return axiosDispatch({
      url: `api/templates/${templateId}`
    })
  },

  searchStrategyById(_, strategyId) {
    return axiosDispatch({
      url: `/api/strategy/${strategyId}`
    })
  },

  searchWhatsAppTemplatesByWorkspaceId(_, workspaceId) {
    return axiosDispatch({
      url: `${gerenciadorTemplate}/${workspaceId}?forcarAtualizacao=true`
    })
  },

  createWhatsAppVariableTemplates(_, { templateId, variables }) {
    return axiosDispatch({
      method: 'POST',
      url: `${gerenciadorTemplate}/variaveis/mapear/${templateId}`,
      data: variables
    })
  },

  searchWhatsAppVariableTemplates(_, { templateId }) {
    return axiosDispatch({
      url: `${gerenciadorTemplate}/variaveis/listar/template/${templateId}`
    })
  },

  searchWhatsAppVariableTemplatesId(_, id) {
    return axiosDispatch({
      url: `${gerenciadorTemplate}/template/${id}`
    })
  }
}

export default strategyActions
