import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["src", "alt"];
import { computed } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'JusIcon',
  props: {
    icon: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: ''
    }
  },
  emits: ['click', 'hover'],
  setup(__props) {
    const props = __props;
    const src = computed(() => {
      return `https://s3.sa-east-1.amazonaws.com/assets.acordo.app/icons/ic-${props.icon}.svg`;
    });
    return (_ctx, _cache) => {
      return __props.icon ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: src.value,
        alt: __props.alt,
        draggable: "false",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('click')),
        onMouseover: _cache[1] || (_cache[1] = $event => _ctx.$emit('hover'))
      }, null, 40, _hoisted_1)) : _createCommentVNode("", true);
    };
  }
});