import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "flex flex-col justify-start items-center gap-4 w-full h-full"
};
const _hoisted_2 = {
  key: 0,
  id: "alert-additional-content-4",
  class: "p-4 mb-4 text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50",
  role: "alert"
};
import { onMounted, ref } from 'vue';
import MajoracaoBase from './partials/MajoracaoBase.vue';
import useDisputeStore from '@/stores/disputeStore';
import JusIcon from '@/components/atoms/JusIcon.vue';
import { validate as uuidValidate } from 'uuid';
import useNotification from '@/composables/notification';
export default /*@__PURE__*/_defineComponent({
  __name: 'IncreasesView',
  setup(__props) {
    const disputeStore = useDisputeStore();
    const majoracoes = ref([]);
    const route = new URL(location.href);
    const uuid = route.pathname.split('/').pop() ?? '';
    const email = route.searchParams.get('email') ?? '';
    onMounted(async () => {
      try {
        if (uuidValidate(uuid)) {
          const majoracao = await disputeStore.searchIncreaseByCode(uuid, email);
          if (majoracao != null) {
            majoracoes.value = [majoracao];
          }
        }
      } catch (error) {
        console.error(error);
      }
    });
    async function responderMajoracao(payload) {
      try {
        const majoracao = await disputeStore.createIncrease(uuid, email, payload);
        if (majoracao != null) {
          majoracoes.value = [majoracao];
        }
        useNotification.success({
          message: 'Majoração respondida com sucesso!'
        });
      } catch (error) {
        useNotification.error({});
        console.error(error);
      }
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(JusIcon, {
        icon: "projuris-simbolo-principal",
        class: "w-[4em] mt-4"
      }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(majoracoes.value, (majoracao, majoracaoIndex) => {
        return _openBlock(), _createBlock(MajoracaoBase, {
          key: majoracaoIndex,
          value: majoracao,
          onAprovar: _cache[0] || (_cache[0] = $event => responderMajoracao($event)),
          onRejeitar: _cache[1] || (_cache[1] = $event => responderMajoracao($event))
        }, null, 8, ["value"]);
      }), 128)), majoracoes.value.length === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[2] || (_cache[2] = [_createElementVNode("div", {
        class: "flex items-center"
      }, [_createElementVNode("h3", {
        class: "text-lg font-medium"
      }, " Majoração não encontrada. ")], -1), _createElementVNode("div", {
        class: "mt-2 mb-4 text-sm"
      }, [_createTextVNode(" Desculpe, não conseguimos encontrar a majoração solicitada. "), _createElementVNode("br"), _createTextVNode(" Por favor, verifique o código e tente novamente. ")], -1)]))) : _createCommentVNode("", true)]);
    };
  }
});