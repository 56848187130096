var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jus-avatar-user",class:[
    `jus-avatar-user--${_vm.size}`,
    {
      'jus-avatar-user--shadow': _vm.shadow,
      'jus-avatar-user--active': _vm.active,
      'jus-avatar-user--secondary': _vm.purple,
      'jus-avatar-user--danger': _vm.danger,
      'jus-avatar-user--warning': _vm.warning,
      'jus-avatar-user--status': _vm.status,
      'jus-avatar-user--online': _vm.status === 'ONLINE',
    }
  ]},[(_vm.showAvatar)?_c('img',{attrs:{"src":_vm.avatarSrc,"alt":"Usuário"}}):_c('span',[_vm._v(" "+_vm._s(_vm.nameInitials)+" ")]),_c('div',{class:{'communication-ticket-item-plaintiff-status-online': _vm.isOnline}})])
}
var staticRenderFns = []

export { render, staticRenderFns }